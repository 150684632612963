import "ress/ress.css";
import React from "react";
import { Helmet } from "react-helmet";
import { Footer } from "~/organisms/Footer";
import { InsightHeader } from "~/organisms/InsightHeader";
import { InsightDownloadDocs } from "~/organisms/InsightDownloadDocs";
import { InsightInitStyle } from "~/atoms/InsightInitStyle";
import "~/utils/styledJsx";

type Props = {
  children: React.ReactNode;
  title: string;
  description?: string;
  canonicalUrl?: string;
  ogpImageUrl?: string;
};

export const LayoutInsight: React.FC<Props> = ({ children, title, canonicalUrl, ogpImageUrl, description }) => {
  const meta = [
    {
      property: `og:type`,
      content: `website`,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:site_name`,
      content: `Refcome`,
    },
    {
      property: `fb:app_id`,
      content: `2220166827995808`,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:card`,
      content: `summary_large_image`,
    },
    {
      httpEquiv: `content-language`,
      content: `ja`,
    },
    ...(ogpImageUrl ? [
      {
        property: `og:image`,
        content: ogpImageUrl,
      },
      {
        name: `twitter:image`,
        content: ogpImageUrl,
      },
    ] : []
    ),
    ...(description ? [
      {
        name: `twitter:description`,
        content: description,
      },
      {
        name: `description`,
        content: description,
      },
      {
        property: `og:description`,
        content: description,
      }
    ] : []),
    ...(canonicalUrl ? [
      {
        property: `og:url`,
        content: canonicalUrl,
      },
    ] : []),
  ];

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "ja",
        }}
        meta={meta}
      >
        <title>{title}</title>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      </Helmet>
      <InsightInitStyle />
      <style jsx>{`
      .main {
        margin-top: 86px;
      }
    `}</style>
      <InsightHeader />
      <main className="main">{children}</main>
      <InsightDownloadDocs />
      <Footer />
    </>
  )
};

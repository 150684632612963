import React from "react";
import { Link } from "gatsby";
import { ReactComponent as Arrow } from "~/images/inline_svg/right_arrow.svg";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";

const PagerItem: React.FC<{
  isActive: boolean;
  index: number;
  basePath: string;
}> = ({ isActive, index, basePath }) => {
  const pagerIndexPath = index === 1 ? basePath : `${basePath}/page/${index}`;

  return (
    <>
      <style jsx>{`
        .wrapper {
          margin-right: 5px;
        }
        .wrapper :global(a) {
          align-items: center;
          background-color: ${(isActive && colors.orange) || `transparent`};
          border-radius: 50%;
          color: ${(isActive && colors.white) || colors.defaultText};
          display: flex;
          font-size: 15px;
          font-weight: ${(isActive && `bold`) || `500`};
          height: 36px;
          line-height: 36px;
          justify-content: center;
          pointer-events: ${isActive ? "none" : "auto"};
          transition: all 0.3s ease;
          width: 36px;
        }
        .wrapper :global(a):hover {
          background-color: ${!isActive && colors.lightOrange};
          color: ${colors.white};
          cursor: ${isActive ? "default" : "pointer"};
        }
      `}</style>
      <li className="wrapper">
        <Link to={pagerIndexPath}>{index}</Link>
      </li>
    </>
  );
};

export const InsightPager: React.FC<{
  currentPage: number;
  totalPages: number;
  basePath: string;
}> = ({ currentPage, totalPages, basePath }) => {
  const items = Array.from({ length: totalPages }).map((_, index) => {
    return (
      <PagerItem
        key={`PagerItem_${index}`}
        index={index + 1}
        isActive={currentPage - 1 === index}
        basePath={basePath}
      />
    );
  });

  return (
    <>
      <style jsx>{`
        .wrapper {
          margin: 50px 0 0;
          padding: 0 30px;
        }
        .pager {
          display: flex;
          justify-content: center;
          list-style: none;
        }
        .pager :global(svg) {
          fill: ${colors.littleBrightGray};
          width: 9px;
          height: 18px;
          margin: 9px 0 0 0;
        }
        .pagerPreviousItem {
          position: relative;
        }
        .pagerPreviousItem :global(svg) {
          transform: rotate(180deg);
        }
        .pagerNextItem {
          position: relative;
        }
        .pagerPreviousItem :global(a),
        .pagerNextItem :global(a) {
          align-self: center;
          display: flex;
          justify-content: center;
          height: 30px;
          width: 30px;
        }
        @media (${breakPoints.sp}) {
          .arrow {
            position: relative;
            top: 2px;
          }
        }
        @media (${breakPoints.minPc}) {
        }
      `}</style>
      <div className="wrapper">
        <ul className="pager">
          {totalPages > 1 && currentPage !== 1 && (
            <li className="pagerPreviousItem">
              <Link to={currentPage - 1 === 1 ? basePath : `${basePath}/page/${currentPage - 1}`}>
                <Arrow />
              </Link>
            </li>
          )}
          {items}
          {totalPages > 1 && currentPage !== totalPages && (
            <li className="pagerNextItem">
              <Link to={`${basePath}/page/${currentPage + 1}`}>
                <Arrow />
              </Link>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

import React from "react";
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";
import { breakPoints } from "~/utils/variables";
import { colors } from "~/utils/colors";

export const InsightDownloadDocs: React.FC = () => {
  const { referralGuide, referralCase } = useStaticQuery<GatsbyTypes.InsightDownloadsDocsQuery>(graphql`
    query InsightDownloadsDocs {
      referralGuide: file(relativePath: { eq: "referralGuide.jpg" }) {
        childImageSharp {
          fixed(width: 300, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      referralCase: file(relativePath: { eq: "referralCase.jpg" }) {
        childImageSharp {
          fixed(width: 300, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);

  return (
    <>
      <style jsx>{`
        .wrapper {
          background: ${colors.gray};
          text-align: center;
          width: 100%;
        }
        .title {
          color: ${colors.defaultText};
          font-size: 3rem;
        }
        .description {
          margin-top: 15px;
          color: ${colors.defaultText};
          font-size: 1.8rem;
        }
        .docs {
          display: flex;
          list-style: none;
        }
        .docsItem {
          width: 300px;
        }
        .docsItem :global(a) {
          display: block;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 40px 20px;
            margin: 30px auto 0;
          }
          .docs {
            flex-direction: column;
            margin: 30px auto 0;
            align-items: center;
          }
          .docsItem + .docsItem {
            margin-top: 20px;
          }
        }
        @media (${breakPoints.minPc}) {
          .wrapper {
            padding: 60px 20px;
            margin: 50px auto 0;
          }
          .docs {
            flex-direction: row;
            justify-content: center;
            margin: 50px auto 0;
          }
          .docsItem + .docsItem {
            margin-left: 20px;
          }
        }
      `}</style>
      <div className="wrapper">
        <h2 className="title">お役立ち資料</h2>
        <p className="description">リファラル採用の推進に役立つ資料をダウンロードできます</p>
        <ul className="docs">
          <li className="docsItem">
            <Link to={`/downloads/complete`}>
              <Img fixed={referralGuide?.childImageSharp?.fixed} />
            </Link>
          </li>
          <li className="docsItem">
            <Link to={`/downloads/case`}>
              <Img fixed={referralCase?.childImageSharp?.fixed} />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

import React from "react";
import { graphql } from "gatsby";
import { LayoutInsight as Layout } from "~/templates/LayoutInsight";
import { InsightArticles } from "~/organisms/InsightArticles";
import { InsightSidebar } from "~/organisms/InsightSidebar";
import { breakPoints } from "~/utils/variables";
import { InsightPager } from "~/molecules/InsightPager";
import { PATH } from "~/constant/path";
import HeroPc from "~/images/insight/top_pc.png";
import HeroSp from "~/images/insight/top_sp.png";

type Props = {
  data: {
    insightArticles: GatsbyTypes.ContentfulInsightArticleConnection;
    categories: GatsbyTypes.ContentfulInsightCategoryConnection;
  };
  location: Location;
  pageContext: {
    basePath: string;
    insightCategoryContentfulId?: string;
    insightCategoryName?: string;
    currentPage: number;
    limit: number;
    skip: number;
    totalPages: number;
  };
};

const InsightIndex: React.FC<Props> = ({ data, pageContext }) => {
  const { currentPage, totalPages, insightCategoryContentfulId, insightCategoryName } = pageContext;
  const title = insightCategoryContentfulId
    ? `${insightCategoryName} | 紹介したい会社作りを加速させるRefcome（リフカム）のメディア`
    : `紹介したい会社作りを加速させるメディア ｜ Refcome（リフカム）- リファラル採用を活性化するクラウドサービス`;

  return (
    <>
      <style jsx>{`
        .hero {
          position: relative;
        }
        .heroImg {
          display: block;
          width: 100%;
          height: auto;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            width: 100%;
            display: block;
          }
          .heroPc {
            display: none;
          }
          .heroSp {
            display: block;
          }
          .articles {
            margin: 30px auto 0;
            padding: 0 30px;
          }
          .sidebar {
            width: 100%;
          }
        }
        @media (${breakPoints.minPc}) {
          .heroPc {
            display: block;
          }
          .heroSp {
            display: none;
          }
          .wrapper {
            max-width: 1000px;
            margin: 50px auto 0;
            display: flex;
          }
          .articles {
            width: calc(100% - 300px);
            padding-right: 20px;
          }
          .sidebar {
            width: 300px;
          }
        }
      `}</style>
      <Layout title={title}>
        <div className="hero">
          <img className="heroImg heroPc" src={HeroPc} />
          <img className="heroImg heroSp" src={HeroSp} />
        </div>
        <div className="wrapper">
          <section className="articles">
            <InsightArticles
              articles={data.insightArticles}
              currentCategoryId={pageContext.insightCategoryContentfulId}
              categories={data.categories}
            />
            <InsightPager currentPage={currentPage} totalPages={totalPages} basePath={PATH.INSIGHT.to} />
          </section>
          <div className="sidebar">
            <InsightSidebar />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default InsightIndex;

export const CaseIndexQuery = graphql`
  query InsightArticleIndex($limit: Int!, $skip: Int!, $insightCategoryContentfulId: String) {
    insightArticles: allContentfulInsightArticle(
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
      filter: { category: { elemMatch: { contentful_id: { eq: $insightCategoryContentfulId } } } }
    ) {
      totalCount
      edges {
        node {
          id
          category {
            id
            contentful_id
            name
            slug
          }
          contentful_id
          cover {
            id
            contentful_id
            fluid(maxWidth: 660, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
          id
          path
          title
          publishedAt
        }
      }
    }
    categories: allContentfulInsightCategory(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          id
          contentful_id
          slug
          name
        }
      }
    }
  }
`;

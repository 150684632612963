import React from "react";
import { Link } from "gatsby";
import dayjs from "dayjs";
import Img from "gatsby-image";
import { breakPoints } from "~/utils/variables";
import { colors } from "~/utils/colors";
import { PATH } from "~/constant/path";

type Props = {
  articles: GatsbyTypes.ContentfulInsightArticleConnection;
  categories: GatsbyTypes.ContentfulInsightCategoryConnection;
  currentCategoryId?: string;
};

export const InsightArticles: React.FC<Props> = ({ articles, categories, currentCategoryId }) => {
  return (
    <>
      <style jsx>{`
        .wrapper {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
        .contentTitle {
          font-weight: 700;
        }
        .contentTab {
          display: flex;
          list-style: none;
        }
        .contentTabItem {
          color: ${colors.lightText};
          position: relative;
        }
        .contentTabItemActive {
          font-weight: bold;
          color: ${colors.defaultText};
          position: relative;
        }
        .contentTabItem :global(a) {
          color: ${colors.lightText};
        }
        .contentTabItem :global(a:hover) {
          color: ${colors.orange};
        }
        .contentTabItemActive :global(a) {
          color: ${colors.defaultText};
        }
        .contentTabItemActive :global(a:hover) {
          color: ${colors.defaultText};
        }
        .contentTabItemActive:after {
          content: "";
          background-color: ${colors.orange};
          width: 100%;
          position: absolute;
          bottom: 0;
          height: 3px;
          border-radius: 6px;
          left: 0;
          bottom: -10px;
        }
        .articles {
          margin: 30px auto 0;
          width: 100%;
        }
        .articleImage {
          display: block;
          object-fit: cover;
          box-shadow: 0 4px 32px rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          width: 100%;
        }
        .articleHeader {
          display: flex;
          margin-top: 15px;
          width: 100%;
          align-items: center;
        }
        .labelReferral {
          background-color: #daf7f3;
          border-radius: 30px;
          color: #76c8c8;
          font-weight: bold;
          font-size: 1.2rem;
          padding: 4px 12px;
        }
        .labelInterview {
          background: #d7e3ff;
          border-radius: 30px;
          color: #779cf7;
          font-weight: bold;
          font-size: 1.2rem;
          padding: 4px 12px;
        }
        .publishedAt {
          text-align: right;
          color: ${colors.lightText};
          font-size: 1.2rem;
          margin-left: auto;
        }
        .articleTitle {
          color: ${colors.defaultText};
          font-size: 1.7rem;
          font-weight: 500;
          margin: 10px 0 0;
        }
        @media (${breakPoints.sp}) {
          .contentTitle {
            font-size: 2.1rem;
          }
          .contentTab {
            margin-top: 20px;
          }
          .contentTabItem {
            font-size: 1.3rem;
            padding: 0 5px;
          }
          .contentTabItemActive {
            font-size: 1.3rem;
            padding: 0 5px;
          }
          .articles {
            margin: 0 auto;
            width: 100%;
          }
          .article {
            margin-top: 50px;
            width: 100%;
          }
          .article:first-child {
            margin-top: 30px;
          }
        }
        @media (${breakPoints.minPc}) {
          .contentHeader {
            display: flex;
            align-items: flex-start;
          }

          .contentTitle {
            font-size: 2.4rem;
          }
          .contentTab {
            margin-left: 50px;
            margin-top: 8px;
          }
          .contentTabItem {
            font-size: 1.5rem;
            padding: 0 10px;
          }
          .contentTabItemActive {
            font-size: 1.5rem;
            padding: 0 10px;
          }
          .articles {
            display: flex;
            flex-wrap: wrap;
          }
          .article {
            width: calc(50% - 30px);
          }
          .article:nth-child(odd) {
            margin: 0 0 30px;
          }
          .article:nth-child(even) {
            margin: 0 0 30px 30px;
          }
        }
      `}</style>
      <div className="wrapper">
        <div className="contentHeader">
          <h2 className="contentTitle">新着記事</h2>
          <ul className="contentTab">
            <li className={(!currentCategoryId && `contentTabItemActive`) || `contentTabItem`}>
              <Link to={PATH.INSIGHT.to}>すべて</Link>
            </li>
            {categories.edges.map((edge) => (
              <li
                key={`categories_${edge.node.contentful_id}`}
                className={
                  (currentCategoryId && currentCategoryId === edge.node.contentful_id && `contentTabItemActive`) ||
                  `contentTabItem`
                }
              >
                <Link to={`${PATH.INSIGHT.to}/${edge.node.slug}`}>{edge.node.name}</Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="articles">
          {articles.edges.map((edge) => {
            const { category, contentful_id, path, publishedAt, title } = edge.node;
            return (
              <article key={`articles_${contentful_id}`} className="article">
                <Link to={`${path || `${PATH.INSIGHT.to}/${contentful_id}`}`}>
                  <div className="articleImage">
                    <Img fluid={edge.node.cover?.fluid} />
                  </div>
                  <div className="articleHeader">
                    {category?.map((c) => (
                      <div key={`category_${contentful_id}_${c?.contentful_id}`} className="labelReferral">
                        {c?.name}
                      </div>
                    ))}
                    {/* TODO: hide until recent articles increase */}
                    {/* <div className="publishedAt">{dayjs(publishedAt).format("YYYY.MM.DD")}</div> */}
                  </div>
                  <h2 className="articleTitle">{title}</h2>
                </Link>
              </article>
            );
          })}
        </div>
      </div>
    </>
  );
};

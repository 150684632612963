import React from "react";
import Img from "gatsby-image";
import { Link, useStaticQuery, graphql } from "gatsby";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { PATH } from "~/constant/path";

export const InsightHeader: React.FC = () => {
  const { logo, logoInsight } = useStaticQuery<GatsbyTypes.InsightHeaderLogoQuery>(graphql`
    query InsightHeaderLogo {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 120, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
      logoInsight: file(relativePath: { eq: "logo_insight.png" }) {
        childImageSharp {
          fixed(height: 24, quality: 100) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);
  return (
    <>
      <style jsx>{`
        .wrapper {
          background-color: ${colors.white};
          padding: 20px 30px;
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          z-index: 100;
        }
        .container {
          display: flex;
          align-items: center;
          margin: 0 auto;
          max-width: 1000px;
        }
        .logo {
          display: flex;
          height: 32px;
        }
        .logo :global(a) {
          display: flex;
          align-items: flex-start;
        }
        .logoInsight {
          margin-top: 7px;
          margin-left: 5px;
        }
        .navigation {
          margin-left: auto;
        }
        .navigationList {
          align-items: center;
          display: flex;
        }
        .navigationListItem {
          display: block;
        }
        .navigationListItem :global(a) {
          color: ${colors.defaultText};
          font-size: 14px;
          font-weight: 500;
          transition: all 0.2s ease-out;
        }
        .navigationListItem :global(a:hover) {
          opacity: 0.8;
          text-decoration: underline;
        }
        .navigationListItem :global(a:visited) {
          color: ${colors.defaultText};
          font-size: 14px;
          font-weight: 500;
          transition: all 0.2s ease-out;
        }
        .navigationListItem + .navigationListItem {
          margin-left: 30px;
        }
        .cvButtonContainer {
          display: block;
        }
        .cvButtonContainer :global(a) {
          display: block;
          position: relative;
          padding: 12px 10px;
          align-items: center;
          background-image: ${colors.brandGradient};
          background-color: ${colors.brand};
          border-radius: 50px;
          display: flex;
          font-size: 14px;
          font-weight: 500;
          line-height: 1;
          justify-content: center;
          padding: 16px 0;
          position: relative;
          text-align: center;
          text-decoration: none;
          transition: all 0.2s ease-out;
          width: 120px;
        }
        .cvButtonContainer :global(a:hover) {
          opacity: 0.85;
          text-decoration: none;
          transform: translate3d(0, -1px, 0);
        }
        .cvButtonContainer :global(a:before) {
          background-color: ${colors.white};
          border-radius: 50px;
          content: "";
          left: 1px;
          height: calc(100% - 2px);
          position: absolute;
          top: 1px;
          width: calc(100% - 2px);
        }
        .cvButtonText {
          color: ${colors.brand};
          display: block;
          position: relative;
          z-index: 1;
        }
        @media (${breakPoints.sp}) {
          .wrapper {
            padding: 20px;
          }
          .navigation {
            display: none;
          }
        }
      `}</style>
      <header className="wrapper">
        <div className="container">
          <span className="logo">
            <Link to="/">
              <Img fixed={logo?.childImageSharp?.fixed} />
            </Link>
          </span>
          <nav className="navigation">
            <ul className="navigationList">
              <li className="navigationListItem">
                <Link to={PATH.CASES.to}>導入事例</Link>
              </li>
              <li className="navigationListItem">
                <Link to={PATH.EVENTS.to}>イベント</Link>
              </li>
              <li className="navigationListItem">
                <Link to={PATH.INSIGHT.to}>{PATH.INSIGHT.name}</Link>
              </li>
              <li className="navigationListItem">
                <span className="cvButtonContainer">
                  <Link to={PATH.INQUIRY_CONTACT.to}>
                    <span className="cvButtonText">資料請求</span>
                  </Link>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    </>
  );
};

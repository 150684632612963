import React from "react";
import { colors } from "~/utils/colors";
import { fontFamily } from "~/utils/variables";

export const InsightInitStyle: React.FC = () => (
  <style jsx global>{`
    html {
      color: ${colors.defaultText};
      display: flex;
      flex: 1;
      font-family: ${fontFamily.insight};
      font-size: 62.5%;
      height: auto;
      min-height: 100%;
      word-break: break-word;
      -webkit-text-size-adjust: 100%;
      -webkit-font-smoothing: antialiased;
      text-rendering: optimizeLegibility;
      font-feature-settings: "palt";
    }
    body {
      box-sizing: border-box;
      font-size: 1.5rem;
      height: auto;
      margin: 0;
      min-height: 100%;
      width: 100%;
    }
    a,
    a:visited,
    a:hover,
    a:active,
    a * {
      text-decoration: none;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 500;
    }
  `}</style>
);
